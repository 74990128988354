.container {
  flex: 1;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  background-color: rgba(241, 242, 245, 0.623);
}

.ui.form {
  width: 100%;
}

.ui.form .ui.label {
  font-size: small;
  width: 100%;
  margin-bottom: 10px;
}
