body {
  background-color: #dadada;
}
#root {
  height: 100%;
}
.grid {
  height: 100%;
}
.image {
  margin-top: -100px;
}
.column {
  max-width: 450px;
  margin-left: 5px;
  margin-right: 5px;
}

.ui.form .ui.segment {
  padding-top: 30px;
  padding-bottom: 30px;
}
