.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  text-align: center;
}

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: rgb(244, 188, 4);
}

.HeaderText {
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 188, 4);
  height: 40px;
}

.FooterText {
  color: white;
  font-weight: 500;
}
