.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.ui.menu .ui.container {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.main.container {
  margin-top: 7em;
}
